import moment from 'moment'

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export default function (value) {
    if (!value) {
        return ""
    }
    let lang
    if (window.localStorage.getItem("lang") === "en") {
        lang = "en"
    } else {
        lang = "cs"
    }
    return capitalize(moment(value).lang(lang).format("dddd DD.MM."))
}
