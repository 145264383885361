<template>
  <div id="app">
      <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
html {
  background-color: #f3ba53;
}
@font-face {
  font-family: "Transcript Pro";
  src: url("~@/assets/Transcript_Pro_ono_Bold_4.ttf");
}
@font-face {
  font-family: "Transcript Mono";
  src: url("~@/assets/Transcript Pro Mono Regular.ttf");
}
</style>
