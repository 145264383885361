import translations from "@/translations/translations.json"

export default function (path) {
    let cur = translations
    path.split(".").forEach(it =>
        cur = cur[it]
    )
    if (window.localStorage.getItem("lang") === "en") {
        return cur.en
    } else {
        return cur.cs
    }
}
