export default function (value) {
    if (typeof value == "object") {
        if (window.localStorage.getItem("lang") === "en") {
            return value.en
        } else {
            return value.cs
        }
    }
    return value
}
