import Vue from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/js/bootstrap.min'
import VueRouter from 'vue-router'
import Routes from './routes'
import TranslationFilter from "@/filters/TranslationFilter";
import StaticTranslationFilter from "@/filters/StaticTranslationFilter";
import wysiwyg from "vue-wysiwyg";
import TimeFilter from "@/filters/TimeFilter";
import DateFilter from "@/filters/DateFilter";
import DateWithNameFilter from "@/filters/DateWithNameFilter";
import DateIdFilter from "@/filters/DateIdFilter";
import SubstringFilter from "@/filters/SubstringFilter";
import VueParallaxJs from 'vue-parallax-js'

Vue.use(VueParallaxJs)
Vue.use(VueRouter);
Vue.use(wysiwyg, {});

Vue.filter("translation", TranslationFilter)
Vue.filter("staticTranslation", StaticTranslationFilter)
Vue.filter("time", TimeFilter)
Vue.filter("date", DateFilter)
Vue.filter("dateWithName", DateWithNameFilter)
Vue.filter("dateId", DateIdFilter)
Vue.filter("substring", SubstringFilter)

const router = new VueRouter({
    mode: 'history',
    routes: Routes
});

new Vue({
    render: h => h(App),
    router: router
}).$mount('#app')
