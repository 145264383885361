const contact = () => import( "./components/base/contact")
const sponsors = () => import( "@/components/base/sponsors")
const media = () => import( "@/components/base/media")
const NewsPage = () => import( "@/components/base/NewsPage")
const NewsDetail = () => import( "@/components/base/NewsDetail")
const Program = () => import( "@/components/base/program/Program")
const Archiv = () => import( "@/components/base/Archiv")
const FoodStreet = () => import( "@/components/base/FoodStreet")
const Interpret = () => import( "@/components/base/program/Interpret")
const Localities = () => import( "@/components/base/program/Localities")
const Directors = () => import( "@/components/base/program/Directors")
// const BoardPavementGrass = () => import( "@/components/base/program/BoardPavementGrass")
const DaysGallery = () => import( "@/components/base/gallery/DaysGallery")
const YearGallery = () => import( "@/components/base/gallery/YearGallery")
const aboutFestival = () => import( "./components/base/aboutFestival")
const pageHome = () => import( "./components/base/pageHome/pageHome")
const Interprets = () => import( "@/components/base/program/Interprets")
const Busking = () => import( "@/components/base/program/Busking")
const login = () => import( "./components/admin/login")
const administration = () => import( "./components/admin/administration")
const program = () => import( "@/components/admin/Program/program")
const programEvent = () => import( "@/components/admin/Program/programEvent")
const programInterpret = () => import( "@/components/admin/Program/programInterpret")
const ProgramDirector = () => import( "@/components/admin/Program/ProgramDirector")
const programPlace = () => import( "@/components/admin/Program/programPlace")
const programPlaceEdit = () => import( "@/components/admin/Program/programPlaceEdit")
const programEventEdit = () => import( "@/components/admin/Program/programEventEdit")
const programInterpretEdit = () => import( "@/components/admin/Program/programInterpretEdit")
const ProgramDirectorEdit = () => import( "@/components/admin/Program/ProgramDirectorEdit")
const programPeriodEdit = () => import( "@/components/admin/Program/programPeriodEdit")
const ProgramGenreEdit = () => import( "@/components/admin/Program/ProgramGenreEdit")
const ProgramGenre = () => import( "@/components/admin/Program/ProgramGenre")
const adminContact = () => import( "@/components/admin/contact/contact")
const galleryAlbum = () => import( "@/components/admin/gallery/galleryAlbum")
const galleryAlbumEdit = () => import( "@/components/admin/gallery/galleryAlbumEdit")
const contactEdit = () => import( "@/components/admin/contact/contactEdit")
const adminNews = () => import( "@/components/admin/news/adminNews")
const newsEdit = () => import( "@/components/admin/news/newsEdit")
const Subscribe = () => import( "@/components/admin/subscribe/Subscribe")
const UnSubscribePage = () => import( "@/components/base/UnSubscribePage")
const Director = () => import( "@/components/base/program/Director")
export default [
    {path: '/', component: pageHome},
    {path: '/about', component: aboutFestival},
    {path: '/sponsors', component: sponsors},
    {path: '/media', component: media},
    {path: '/foodStreet', component: FoodStreet},
    {path: '/gallery', component: YearGallery},
    {path: '/gallery/album/:id', component: DaysGallery},
    {path: '/contact', component: contact},
    {path: '/news', component: NewsPage},
    {path: '/news/detail/:id', component: NewsDetail},
    {path: '/program', component: Program},
    {path: '/program/interpret/:id', component: Interpret},
    {path: '/program/director/:id', component: Director},
    // {path: '/na-prknech-dlazbe-trave', component: BoardPavementGrass},
    {path: '/archiv', component: Archiv},
    {path: '/interprets', component: Interprets},
    {path: '/localities', component: Localities},
    {path: '/directors', component: Directors},
    {path: '/busking', component: Busking},
    {path: '/login', component: login},
    {path: '/admin', component: administration},
    {path: '/admin/program', component: program},
    {path: '/admin/program/event', component: programEvent},
    {path: '/admin/program/interpret', component: programInterpret},
    {path: '/admin/program/director', component: ProgramDirector},
    {path: '/admin/program/place', component: programPlace},
    {path: '/admin/program/place/edit', component: programPlaceEdit},
    {path: '/admin/program/place/edit/:id', component: programPlaceEdit},
    {path: '/admin/program/event/edit', component: programEventEdit},
    {path: '/admin/program/event/edit/:id', component: programEventEdit},
    {path: '/admin/program/interpret/edit', component: programInterpretEdit},
    {path: '/admin/program/interpret/edit/:id', component: programInterpretEdit},
    {path: '/admin/program/director/edit', component: ProgramDirectorEdit},
    {path: '/admin/program/director/edit/:id', component: ProgramDirectorEdit},
    {path: '/admin/program/period/edit', component: programPeriodEdit},
    {path: '/admin/program/period/edit/:id', component: programPeriodEdit},
    {path: '/admin/program/genre', component: ProgramGenre},
    {path: '/admin/program/genre/edit', component: ProgramGenreEdit},
    {path: '/admin/program/genre/edit/:id', component: ProgramGenreEdit},
    {path: '/admin/contact', component: adminContact},
    {path: '/admin/contact/edit', component: contactEdit},
    {path: '/admin/contact/edit/:id', component: contactEdit},
    {path: '/admin/album', component: galleryAlbum},
    {path: '/admin/album/edit', component: galleryAlbumEdit},
    {path: '/admin/album/edit/:id', component: galleryAlbumEdit},
    {path: '/admin/news', component: adminNews},
    {path: '/admin/news/edit', component: newsEdit},
    {path: '/admin/news/edit/:id', component: newsEdit},
    {path: '/admin/subscribe', component: Subscribe},
    {path: '/unsubscribe', component: UnSubscribePage},
    {path: '*', redirect: '/'}
]
